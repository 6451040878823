import React from "react"
import Prices from "../../components/Prices"
import "../../styles/prices.scss"
import dataPrice from "../../data/price.json"
// import { Link } from "gatsby"

import SEO from "../../components/seo"

const description = "Cennik tapicer meblowy Konin"
const keywords = ["cennik tapicer"]

const upholsteryFuturePrice = ({ data }) => (
    <>
        <SEO
            title="Cennik | Rymarz"
            description={description}
            keywords={keywords}
        />
        <div className="prices container">
            <h1 className="title">CENNIK</h1>
            <div className="headerContainer">
                <h2 className="header">Tapicerstwo meblowe</h2>
                <p className="desc">Publikowany poniżej cennik ma charakter poglądowy. Podane kwoty nie zawierają cen materiałów oraz użytych dodatków. Dokładna wycena usługi następuje podczas przyjęcia towaru.</p>
            </div>
            <Prices
                data={dataPrice.tapicer.meblowe}
            />

        </div>
    </>
)


export default upholsteryFuturePrice